import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col } from "reactstrap"
import { AiOutlineInstagram } from 'react-icons/ai'
import ImageCarousel from "../components/ImageCarousel"

import image1 from "../images/about/1.jpg"
import image2 from "../images/about/2.jpg"
import image3 from "../images/about/3.jpg"
import image4 from "../images/about/4.jpg"

const items = [
  {
    src: image1,
    caption: 'showpdx event'
  },
  {
    src: image2,
    caption: 'showpdx event'
  },
  {
    src: image3,
    caption: 'big leaf maple from crosscut'
  },
  {
    src: image4,
    caption: 'working on the shop'
  }
]

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <Row className="px-2">
      <Col md="6">
        <h6>
          Custom hand-built furniture using materials and hardwoods from the
          Pacific Northwest
        </h6>
        <p>
          Erethworks is a small custom furniture shop with pieces designed and
          built by
          <span className="text-uppercase"> Geoff Ereth.</span>
        </p>
        <p>
          In 2018 we came across some materials from Portland hazard trees that had been cut
          down, milled, and air-dried. The colors and grain patterns
          from the local hardwoods inspired us to explore new seating and table
          designs that would be a joy to experience in our home. Erethworks is
          the outcome of this ongoing adventure.
        </p>

        <a className="about-icon" href="https://instagram.com/erethworks">
          <AiOutlineInstagram size={30} />
        </a>
      </Col>
      <Col md="6" className="py-4">
        <ImageCarousel items={items} />
      </Col>
    </Row>
  </Layout>
)

export default AboutPage
